<template>
  <div>
    <div class="flex px-3 h-[4.188rem] border-b justify-between items-center">
      <div class="w-full flex gap-x-2">

        <Button
          id="create-custom-domains-button"
          type="button"
          class="bg-blue-600 hover:bg-blue-700"
          buttonClass="btn-md"
          :disabledProp="disabledProp"
          @click.prevent="$emit('OpenParentModal')"
        >
          <template v-slot:label>Add Domain</template>
        </Button>

      </div>
      <div class="w-full flex justify-center items-center">
        <p class="text font-bold text-center !text-[1.125rem] pr-[0.2rem] !text-[#3C4549]">Custom Domain</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="14"
          viewBox="0 0 13 14"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.70445 2.29492C4.10627 2.29492 2 4.40121 2 6.99947C2 9.59773 4.10627 11.704 6.70445 11.704C9.30263 11.704 11.4089 9.59773 11.4089 6.99947C11.4089 4.40121 9.30263 2.29492 6.70445 2.29492ZM0.5 6.99947C0.5 3.5728 3.27781 0.794922 6.70445 0.794922C10.1311 0.794922 12.9089 3.5728 12.9089 6.99947C12.9089 10.4261 10.1311 13.204 6.70445 13.204C3.27781 13.204 0.5 10.4261 0.5 6.99947ZM6.70476 6.04891C7.11898 6.04895 7.45473 6.38477 7.45469 6.79898L7.45445 9.49304C7.45441 9.90726 7.1186 10.243 6.70438 10.243C6.29017 10.2429 5.95441 9.90712 5.95445 9.49291L5.95469 6.79885C5.95473 6.38463 6.29055 6.04888 6.70476 6.04891ZM6.70445 3.75581C6.29024 3.75581 5.95445 4.0916 5.95445 4.50581C5.95445 4.92002 6.29024 5.25581 6.70445 5.25581H6.71029C7.12451 5.25581 7.46029 4.92002 7.46029 4.50581C7.46029 4.0916 7.12451 3.75581 6.71029 3.75581H6.70445Z"
            fill="#3C4549"
          />
        </svg>
      </div>
      <div class="w-full flex items-center justify-end space-x-2  ">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
  },
  props: {
    disabledProp: {
      type: [Boolean, String],
      required: true,
      default: true,

    }
  },
  components: {
    Button: () => import("@/ui/ui-kit/v2/Button.vue"),
  },

  computed: {},
  methods: {},
};
</script>
